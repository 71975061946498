.survey-question {
  display: flex;
  align-items: center;

  &-info {
    flex-grow: 1;
    padding-bottom: 2.5em;
    text-align: center;

    > h2 {
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0.5em;
    }

    > span {
      font-size: 2em;
      font-weight: bold;
    }
  }

  &-open-ended {
    display: flex;
    margin: 1.3em 0;

    p {
      margin: 0;
      white-space: pre-wrap;
    }

    > span {
      font-size: 1.3em;
      opacity: 0.4;
      padding: 0 0.3em;

      &:first-child {
        align-self: flex-start;
      }

      &:last-child {
        align-self: flex-end;
      }
    }
  }
}
