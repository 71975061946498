@mixin darkTheme {
  html[data-theme=dark] & {
    @content;
  }
}

@mixin lightTheme {
  html[data-theme=light] & {
    @content;
  }
}

// Keep in sync with theme.tsx
// TODO: Deduplicate somehow?
html[data-theme=dark] {
  --accent: hsl(340, 90%, 70%);
  --accent-background: hsl(340, 10%, 7%);
  --accent-hover: hsl(340, 30%, 50%);
  --accent-light: hsl(340, 90%, 80%);
  --background: hsl(340, 10%, 14%);
  --content: #eee;
  --control: hsl(220, 90%, 65%);
  --control-background: #9cf2;
  --control-heavy: hsl(220, 90%, 80%);
  --rating--3: hsl(0, 100%, 60%);
  --rating--3-background: hsla(0, 100%, 60%, 0.067);
  --rating--3-hover: hsl(0, 100%, 45%);
  --rating--2: #f55;
  --rating--1: #f77;
  --rating-0: #fb3;
  --rating-1: #6b6;
  --rating-2: #4b4;
  --rating-3: #2b2;
  --rating-3-background: #2b21;
  --secondary-accent: hsl(280, 90%, 70%);
  --secondary-accent-hover: hsl(280, 30%, 50%);

  color-scheme: dark;
}

html[data-theme=light] {
  --accent: #f6a;
  --accent-background: #fff0f8;
  --accent-hover: #fde;
  --accent-light: #f9c;
  --background: #fff;
  --content: #333;
  --control: hsl(210, 100%, 80%);
  --control-background: #9cf2;
  --control-heavy: hsl(220, 100%, 70%);
  --rating--3: hsl(0, 90%, 60%);
  --rating--3-background: hsla(0, 90%, 60%, 0.067);
  --rating--3-hover: hsl(0, 90%, 75%);
  --rating--2: #f44;
  --rating--1: #f66;
  --rating-0: #fa2;
  --rating-1: #5a5;
  --rating-2: #3a3;
  --rating-3: #1a1;
  --rating-3-background: #1a11;
  --secondary-accent: rgb(236, 94, 255);
  --secondary-accent-hover: rgb(248, 207, 253);

  color-scheme: light;
}
