.has-tooltip {
  display: inline-block;
  position: relative;
}

.tooltip {
  background-color: var(--accent-background);
  border: 1px solid var(--content);
  color: var(--content);
  font-weight: normal;
  left: 50%;
  max-width: 500px;
  padding: 0.5em;
  position: absolute;
  transform: translateX(-50%);
  white-space: initial;
  width: max-content;
  z-index: 2;

  &--above {
    bottom: calc(100% + 0.5em);
  }

  &--below {
    top: calc(100% + 0.5em);
  }

  &__hover-area {
    inset: -1px;
    position: absolute;
    z-index: -1;

    .tooltip--above & {
      bottom: calc(-0.5em - 2px);
    }

    .tooltip--below & {
      top: calc(-0.5em - 2px);
    }
  }

  &__triangle {
    background-color: var(--accent-background);
    border-bottom: 1px solid var(--content);
    border-right: 1px solid var(--content);
    height: 10px;
    left: 50%;
    position: absolute;
    width: 10px;
    z-index: -1;

    .tooltip--above & {
      bottom: -1px;
      transform: translateX(-50%) translateY(50%) rotateZ(45deg);
    }

    .tooltip--below & {
      top: -1px;
      transform: translateX(-50%) translateY(-50%) rotateZ(-135deg);
    }
  }
}
